






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Shipping Vendors Management",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Name",
              sortable: true,
            },
            description: {
              text: "Description",
              sortable: true,
            },
            countryCodes: {
              text: "Country Codes",
              options: {
                labels: true,
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastSyncTime: {
              text: "Last Sync Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastSyncStatus: {
              text: "Last Sync Status",
              sortable: true,
              options: {
                preTransform(_, item) {
                  return item.lastSyncSuccessTime && !item.lastSyncError;
                },
                boolean: true,
              },
            },
            lastSyncSuccessTime: {
              text: "Last Sync Success Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              syncOrders: {
                attrs: {},
                content: {
                  icon: "mdi-sync",
                },
                on: {
                  async xClick({ self }) {
                    const { item, dataTable } = self.context();
                    self.loading.value = true;
                    await coreApiClient.call("shippingVendors", "syncOrdersForItem", {
                      id: item._id,
                    });
                    self.loading.value = false;
                    dataTable.refresh();
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Sync Orders",
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("shippingVendors", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("shippingVendors", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "shippingVendors",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("shippingVendors", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Name",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              countryCodes: {
                type: "textMultiple",
                attrs: {
                  label: "Country Codes",
                },
              },
              lastSyncSuccessTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Last Sync Success Time",
                },
              },
              colisPriveUrl: {
                attrs: {
                  label: "Colis Prive URL",
                },
              },
              colisPriveUsername: {
                attrs: {
                  label: "Colis Prive Username",
                },
              },
              colisPrivePassword: {
                attrs: {
                  label: "Colis Prive Password",
                },
              },
              colisPriveCustomerId: {
                attrs: {
                  label: "Colis Prive Customer ID",
                },
              },
              colisPriveAccountId: {
                attrs: {
                  label: "Colis Prive Account ID",
                },
              },
              myDelivengoUrl: {
                attrs: {
                  label: " MyDelivengo URL",
                },
              },
              myDelivengoApiKey: {
                attrs: {
                  label: "MyDelivengo API Key",
                },
              },
              myDelivengoSenderFirstName: {
                attrs: {
                  label: "MyDelivengo Sender First Name",
                },
              },
              myDelivengoSenderLastName: {
                attrs: {
                  label: "MyDelivengo Sender Last Name",
                },
              },
              myDelivengoSenderAddress: {
                attrs: {
                  label: "MyDelivengo Sender Address",
                },
              },
              myDelivengoSenderCity: {
                attrs: {
                  label: "MyDelivengo Sender City",
                },
              },
              myDelivengoSenderPostalCode: {
                attrs: {
                  label: "MyDelivengo Sender Postal Code",
                },
              },
              myDelivengoSenderPhone: {
                attrs: {
                  label: "MyDelivengo Sender Phone",
                },
              },
            },
            insertForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "countryCodes",
                  "lastSyncSuccessTime",
                  "colisPriveUrl",
                  "colisPriveUsername",
                  "colisPrivePassword",
                  "colisPriveCustomerId",
                  "colisPriveAccountId",
                  "myDelivengoUrl",
                  "myDelivengoApiKey",
                  "myDelivengoSenderFirstName",
                  "myDelivengoSenderLastName",
                  "myDelivengoSenderAddress",
                  "myDelivengoSenderCity",
                  "myDelivengoSenderPostalCode",
                  "myDelivengoSenderPhone",
                ],
              },
            },
            editForm: userManager.checkRole(["admin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "description",
                  "countryCodes",
                  "lastSyncSuccessTime",
                  "colisPriveUrl",
                  "colisPriveUsername",
                  "colisPrivePassword",
                  "colisPriveCustomerId",
                  "colisPriveAccountId",
                  "myDelivengoUrl",
                  "myDelivengoApiKey",
                  "myDelivengoSenderFirstName",
                  "myDelivengoSenderLastName",
                  "myDelivengoSenderAddress",
                  "myDelivengoSenderCity",
                  "myDelivengoSenderPostalCode",
                  "myDelivengoSenderPhone",
                ],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
